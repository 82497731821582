<template>
  <div class="auth-page"  element-loading-text="用户登录中……" >
<!--    <img src="~@/assets/img/login-bg1.png">

    <div class="auth-content">

     
    </div>-->
  </div>
</template>
<script>
import { getUUID , getTokenName } from "@/utils/index";
import { login ,getCaptchaUrl,oalogin } from '@/api/sys/user'
import { listByUserid} from '@/api/hbi/project/bscreen.js';
export default {
  data() {
    return {
      ticket: this.$route ? this.$route.query.ticket :"",
      dataForm: {
        userNo: "",
        password: "",
        uuid: "",
        captcha: ""
      },
      oaloading:true,
      visible:false,
      logining: false
    };
  },
  created() {
    this.oaLogin();
  },
  methods: {
    getbscreens(){
      listByUserid().then(({data}) => {
        if(data && data.code == 0) {
          let id="";
          if(data.data!=null&&data.data.length>0){
            id=data.data[0].bscreenId
            this.$router.push({
              path: '/stat',
              query: {id: id}
            })
          }else{
            this.$router.replace({name: "home"}).catch(e2 => {

            });
          }
        } else if(data && data.msg) {
          this.$message.error(data.msg)
        }
      }).finally(() => {
        this.categoryLoading = false
      })
    },
    // 提交表单
    oaLogin() {
      if(!this.ticket){
        return ;
      }
      const loading = this.$loading({
        lock: true,
        text: '请稍等，用户登录中……',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });

      oalogin(this.ticket).then(({data}) => {
        if (data && data.code === 0) {
          const tokenName = getTokenName()
          this.$cookie.set(tokenName, data.data.token);
          this.$cookie.set("Username", data.data.user.username);
          this.$cookie.set("ticket", this.ticket);
          this.$nextTick().then(() => {
            this.getbscreens()
          })
        } else {
          this.$message.error(data.msg);
        }
      })
      .catch(err => {
        this.$message.error(err);
      })
      .finally(() => {
        loading.close();
      });
    },

  }
};
</script>
<style lang="scss" scoped>
.auth-page {

  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
   //background: url(~@/assets/img/login-bg.png) no-repeat 0;
  &:before {
    position: fixed;
    top: 100;
    left: 100;
    z-index: -1;
    width: 100%;
    height: 100%;
    content: "";
    background-size: cover;
  }

  img{
    width: 100%;
    height: 100%;
    display: block;
  }

 

  .auth-content {
      position: absolute;
      z-index: 1;
      width: 500px;
      right: 25%;
      margin-right: -200px;
      top: 50%;
      margin-top: -220px;

    .login-page {
      position: relative;
      height: 100%;

      display: -webkit-box;
      display: -ms-flexbox;
     

      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: end;
      -ms-flex-pack: end;
      justify-content: flex-end;



      .login-form {
        width: 500px;
        padding: 50px;
        border: 1px solid #bfe1fd;
        background-color: #1466a0;
        /*box-shadow: 10px 10px 5px #888888;*/
        border-radius: 5px;

        .form-content {
          padding: 0px 40px;
        }


        .login-title-new{

          font-size: 28px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          margin-bottom: 30px;
          color: #ffffff;
          text-align: center;
        }

        input {
          height: 47px;
        }

        .login-form-header {
          text-align: center;
          margin-top: 5rem;
        }

        .login-item-center {
          text-align: center;

          ::v-deep.el-button--primary {
            background: linear-gradient(-90deg, #50B9FB, #7697FE);
            border-radius: 28px;

           
          }

           ::v-deep.el-button--primary:active{
             background: #7697FE;
            }
        }
        .login-item-center button {
          width: 100%;
        }
        .login-captcha {
          width: 58%;
        }
      }
    }
  }

  .auth-footer {
    text-align: center;
    line-height: 30px;
    position: absolute;
  
    bottom: 10%;
    left: 60%;
    margin-left:-150px;

    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #1A1A1A;
  }
}

@media screen and (max-width: 768px) {
  .login-page {
    background: none;
  }
}
</style>
